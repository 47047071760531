.form-attdados {
	display: grid;
	width: 50%;
	grid-template-rows: repeat(8, 3em);
	margin: auto;
	margin-top: 4em;
	margin-bottom: 4em;
	text-align: center;

	#patente{
		margin-right: 5em;
	}

	input,select {
		border-radius: 5px;
		border: 1px solid #ececec;
		padding-left: 1em;
		height: 2em;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		width: 40%;
		box-sizing: content-box;
	}

	> div {
		margin: 0;
		> label{
			width: 20%;
			text-align: left;
			border-bottom-right-radius: 0px;
			display: inline-block;
		}
		button {
			margin: 0;
			display: inline-block;
			margin-left: -4px;
		}

		.newsenha{
			margin-right: 5em;
		}
		.fog{
			background-color: #ececec;
		}
	}
}
