$footerheight: 200px;
$footerheight2: 260px;

html {
	font-family: 'Roboto', sans-serif;
  min-height: 100%;
}

body {
	background-color: #FFFFFF;
	position: relative;


	min-height: calc(100vh - #{$footerheight});
	padding-bottom: $footerheight;

	@include tablet-landscape-and-below {
		min-height: calc(100vh - #{$footerheight2});
		padding-bottom: $footerheight2;
	}
}

h2.titulo{
  color: #e83c37;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

div.estatisticas {
	display: grid;
	grid-template-columns: repeat(12,1fr);
	grid-template-rows: 1fr;
	.abertos {
		color: #F3932F;
		text-align: center;
		font-family: 'Roboto', sans-serif;
		font-size: 1.35em;
		grid-column: 1 / 4;
		.number {
			padding-left: 1em;
			font-size: 1.175em;			
 	 		color: #e83c37;
		}
	}
}

button.botao {
  display: block;
	margin: auto;
  margin-top: 1em;
	margin-bottom: 1em;
  -webkit-border-radius: 10;
  -moz-border-radius: 10;
  border-radius: 10px;
  font-family: 'Roboto';
  color: #ffffff;
  font-size: 1em;
  background-color: #F3932F;
  padding: .75em;
	padding-left: 1.5em;
	padding-right: 1.5em;
  text-decoration: none;
  border: none;
	.link {
		text-decoration: none;
		color: #ffffff;
	}
}


a.encerrarOcorrencia{
	text-decoration: none;
	color: #ffffff;
	display: block;
	margin: auto;
  margin-top: 1em;
	margin-bottom: 1em;
  -webkit-border-radius: 10;
  -moz-border-radius: 10;
  border-radius: 10px;
  font-family: 'Roboto';
  color: #ffffff;
  font-size: 1em;
  background-color: #F3932F;
  padding: .75em;
	padding-left: 1.5em;
	padding-right: 1.5em;
}

a.encerrarOcorrencia:hover{
	background-color: green;
}

button.botao:hover, a.voltar:hover, a.novoProduto:hover{
	cursor: pointer;
	background-color: #f77e00;
}

a.novoProduto{
	display: inline-block;

	margin: 0 auto 1rem auto;

	border-radius: 10px;
	border: none;

	font-size: 1rem;
  	font-family: 'Roboto';
  	color: #ffffff;
  	background-color: #F3932F;

	padding: .5em 1.5em;
	text-align: center;
	text-decoration: none;

	width: 200px;

	grid-column: 1 / 5;
	grid-row: 1;
}


button.botao.editar{
	font-size: 0.9em;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

button.botao.atualizar{
	margin-top: 2em;
	width: 30%;
	display: inline-block;
	margin-right: 2em;
}

button.botao.atualizar:hover{
	background-color: #33ad33;
}

button.botao.limpar{
	display: inline-block;
	margin-top: 2em;
	width: 30%;
}
button.botao.limpar:hover{
	background-color: #E83C38;
}

a.voltar {
	display: inline-block;

	margin: 0 auto 1rem auto;

	border-radius: 10px;
	border: none;

	font-size: 1rem;
  font-family: 'Roboto';
  color: #ffffff;
  background-color: #F3932F;

	padding: .5em 1.5em;
	text-align: center;
	text-decoration: none;

	width: 50px;

	grid-column: 10 / 13;	
}

.hidden {
	display: none !important;
}

h1, h2, h3 {
	&.title {
		color: #363636;
		font-weight: 600;
		line-height: 1.125;
	}

	&:not(:last-child) {
		margin-bottom: 0;
	}
}

h1.title {
	font-size: 2rem;
}

h2.title {
	font-size: 1.6rem;
	text-align: center;
}

h3.title {
	font-size: 1.4rem;
}

p.subtitle {
	margin: 0;
	text-align: center;
	color: #4a4a4a;
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.25;
}
