$footerBarHeight: 3em;
$footerBarFontSize: 1.3em;

.footBarWrapper{
	width: 100%;
	background-color: $navbarBackground;
	position: absolute;
	bottom: 0;
	height: $footerBarHeight;
	margin-bottom: 10.5em;
	padding-bottom: 0.5em;

	.telephoneIcon{
		height: 3%;
		width: 3%;
		margin-right: 10px;

		@media (max-width: 700px) {
			height: 5%;
			width: 5%;
		}
		@media (max-width: 400px) {
			display:none;
		}

	}


	.footerbar{
		position: inherit;
		bottom: inherit;
		padding-bottom: inherit;
		height: $footerBarHeight;
		background-color: $footerBorder;
		width: 50%;
		margin-left: 25%;
		-webkit-transform: skew(-40deg);
		-moz-transform: skew(-40deg);
		-o-transform: skew(-40deg);
		-ms-transform: skew(-40deg);
		transform: skew(-40deg);

		@media (max-width: 1000px) {
			height:  5em;
		}

		@media (max-width: 700px) {
			height: 6em;
		}



		> p{
			font-family: 'Roboto',sans-serif;
			text-align: center;
			font-size: $footerBarFontSize;
			color: white;
			-webkit-transform: skew(40deg);
			-moz-transform: skew(40deg);
			-o-transform: skew(40deg);
			-ms-transform: skew(40deg);
			transform: skew(40deg);
			@media (max-width: 700px) {
				font-size: 1em;
			}

		}

	}

	@media (max-width: 1000px) {
		height:  5em;
	}

	@media (max-width: 700px) {
		height: 6em;
	}
	@media (max-width: 500px) {
		display:none;
	}


}
