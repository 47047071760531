.form-usuarios{
	display: grid;
	width: 70%;
	grid-template-rows: repeat(4, 1fr);
	grid-template-columns: repeat(12, 1fr);
	align-items: center;
	margin: auto;

	label {
		align: left;
		white-space: nowrap;
	}
	input,select{
		border-radius: 5px;
		border: 1px solid #ececec;
		padding-left: 1em;
		height: 2em;
		margin: 0.5em;
		box-sizing: content-box;
	}

	#nome {
		text-transform: uppercase;
		grid-column: 2 / 12;
		text-transform: uppercase;
	}
	#cpf {
		grid-column: 2 / 7;
	}
	#patente {
		grid-column: 8 / 12;
	}
	#data {
		grid-column: 2 / 7;
	}
	#telefone {
		grid-column: 8 / 12;
	}
	#email {
		text-transform: lowercase;
		grid-column: 2 / 7;
		text-transform: lowercase;
	}
	#senha {
		grid-column: 8 / 12;
	}
	.botao {
		grid-column: 6 / 7;
	}

	@media screen and (max-width: 900px){
		#cpf {
			grid-column: 2 / 12;
		}
		#patente {
			grid-column: 2 / 12;
		}
		.label {
			grid-column: 1 / 2;
		}
		#data {
			grid-column: 4 / 12;
		}
		#telefone {
			grid-column: 2 / 12;
		}
		#email {
			grid-column: 2 / 12;
		}
		#senha {
			grid-column: 2 / 12;
		}
		.botao {
			grid-column: 1 / 12;
		}
	}
}
