$barraHeight: 7.5rem;
$barraHeight2: 6rem;
$barraHeight3: 4.5rem;

$navbarHeight: 3rem;
$navbarHeight2: 2rem;
$navbarHeight3: 1rem;

$navbarFontColor: #FFFFFF;

.barraSuperior {
  grid-template-columns: 1fr 1.3fr 1fr;
  text-align: center;
  display: grid;

  align-self: center;
  background-color: #ffffff;
  height: $barraHeight;
  align-self: center;

	transition: 1s;

  .logo,.logo2{
    align-self: center;
    font-family: 'Roboto',sans-serif;
    color: rgb(51,51,51);

		@media screen and (max-width: 480px) {
			font-size: 0.9rem;
		}
  }


	.identificacaoUsuario{
		align-self: center;
		font-family: 'Roboto',sans-serif;
		color: rgb(51,51,51);
		@media screen and (max-width: 520px) {
			font-size: 0.6rem;
		}
	}
	.logo2{
		@media screen and (max-width: 520px) {
			font-size: 0.6rem;
		}
	}



  .logo-cb {
		width: 20em;
  }

  @media screen and (max-width: 900px) {
	  .logo-cb {
			width: 10em;
	  }
  }

	&.hidden {


		+ .navbar {

			// Ajuste de posicionamento quando a barra superior está escondida.
			div:not(.clearfix) {
				.submenu {
					top: $navbarHeight;

					@media screen and (max-width: 700px) {
						top: $navbarHeight2 + 3rem;
					}

					@media screen and (max-width: 480px) {
						top: $navbarHeight3 + 1rem;
					}
				}
			}
		}
	}
}

.navbar {
  background-color: $navbarBackground;
	transition: all 0.5s;

  display: grid;
	grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr 1fr;
  &.admin, &.loggedin {
		grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr 1fr 1fr;

    div:not(.clearfix).user .logo {
      @media screen and (max-width: 1180px) {
        transform: skew(0);
      }
    }

    @media screen and (max-width: 1460px) {
      font-size: .9rem;
    }

    @media screen and (max-width: 1180px) {
      grid-template-columns: 0fr 1fr 1fr 2fr 1fr 1fr 1fr;
    }

    @media screen and (max-width: 960px) {
      font-size: .8rem;
    }

    @media screen and (max-width: 960px) {
      font-size: .8rem;
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      min-height: $navbarHeight3;
    }
	}

	grid-auto-rows: auto;
  font-family: 'Roboto', sans-serif;
	font-size: 1.1rem;

  min-height: $navbarHeight;
  width: 100%;
	align-items: center;
	text-align: center;

	box-shadow: 0 5px 25px -10px black;

	@media screen and (max-width: 1280px) {
		font-size: .9rem;
	}

	@media screen and (max-width: 1080px) {
    grid-template-columns: 0fr 1fr 1fr 2fr 1fr 1fr;
	}

	@media screen and (max-width: 900px) {
		font-size: .7rem;
	}

	@media screen and (max-width: 740px) {
		.logo-cb{
			width: 15em;
		}
	}

	@media screen and (max-width: 700px) {
		font-size: .6rem;
	}

  @media screen and (max-width: 500px) {
    font-size: .55rem;
  }

	@media screen and (max-width: 480px) {
		grid-template-columns: 1fr;
		min-height: $navbarHeight3;
	}

	div:not(.clearfix) {
		height: 100%;
		width: 100%;

		display: grid;				// Uso da grid pra centralização vertical de a
		align-items: center;
		> a {
			text-transform: uppercase;
			line-height: $navbarHeight;
			text-decoration: none;
			color: $navbarFontColor;
			@media screen and (max-width: 700px) {
				line-height: $navbarHeight2;
			}

			@media screen and (max-width: 480px) {
				line-height: $navbarHeight3;
			}
		}

		&:hover:not(.user) {
			background-color: $navbarHover;
			transition: background-color 0.5s;

			&.beforeuser {
				& + .user .logo{
					transform: skew(0);
					transition: all 0.2s;
				}
			}

			& .submenu {
				transition: max-height 0.4s;
				// EX: '.submenu.i-2' para quando tiver dois elementos
				@for $i from 1 through 8 {
				  &.i-#{$i} {
						max-height: 2.955rem * $i;
            @media screen and (max-width: 1280px) {
              max-height: 2.71rem * $i;
            }
            @media screen and (max-width: 1080px) {
              max-height: 2.46rem * $i;
            }
            @media screen and (max-width: 900px) {
              max-height: 2.34rem * $i;
            }
            @media screen and (max-width: 740px) {
              max-height: 2.25rem * $i;
            }
					}
				}
			}
		}

		.submenu {
			background-color: $navbarHover;
			max-width: 20rem;
			z-index: 2;
			position: absolute;
			overflow: hidden;
			top: $barraHeight + $navbarHeight;
			max-height: 0;
			transition: max-height 0.4s;

			@media screen and (max-width: 700px) {
				top: $barraHeight2 + $navbarHeight2 + 3rem;
			}

			@media screen and (max-width: 480px) {
				top: $barraHeight3 + $navbarHeight3 + 1rem;
			}

			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				text-align: left;

				li {
					border-top: solid;
					border-top-color: black;
					border-top-width: 1px;

					&:last-child {
						border-bottom: solid;
						border-bottom-color: black;
						border-bottom-width: 1px;
					}

				 	a {
						width: 100%;
						display: inline-block;
						text-decoration: none;
						padding-left: 1rem;
						padding-bottom: 0.75rem;
						padding-top: 0.75rem;
						color: $navbarFontColor;

						&:hover, &:focus {
							background-color: #ffb600;
						}
					}
				}
			}
		}



		@media screen and (max-width: 480px) {
			max-width: 100%;

			@for $i from 1 to 8 {
				&:nth-child(#{$i}) .submenu {
					top: 6.5rem + 1rem * $i;
				}
			}
		}

		&.user {
	    display: grid;
	    grid-template-columns: 1fr 2fr;

	    .logo {
	      background-color: $navbarHover;
	      transform: skew(-30deg);
				transition: all 0.5s;

				@media screen and (max-width: 1080px) {
					transform: skew(0);
				}
	    }

	    .nome {
        display: inline-flex;
	      background-color: $navbarHover;
				margin-left: -1.2rem;
				padding-right: 1.2rem;
	      z-index: 1;

				.icon {
					width: 1.5em;
					height: 1.25em;
					margin: 0.25em;
					vertical-align: middle;
				}
	    }

			@media screen and (max-width: 480px) {
				grid-template-columns: 1fr 1fr;

				.nome {
					margin-left: -1.5rem;
					padding-right: 1.5rem;
				}
			}
	  }
	}
}
