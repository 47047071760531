.listagem-index {
	padding: 1rem;
	margin-left: 200px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr, 1fr;
	div {
		grid-column: 2 / 4;
	}
	ul {
		padding-left: 1rem;
		padding: 1.5rem;
		background-color: #e83c37;
		margin: inherit;
		li {
			list-style-type:none;
		}
		li::before {
			content: "• ";
			font-size: 1.5em;
			color: white;
		}
		a {
			text-decoration: none;
			color: white;
			font-size: 1.1rem;
		}
		a:hover {
			color: rgba(255,255,255,0.8);
		}
	}
	h3 {
		padding: 1rem;
		background-color: #F3932F;
		color: white;
		font-size: 1.2rem;
		margin: inherit;
	}

	@media screen and (max-width: 900px) {
		margin: 0;
		div {
			grid-column: 2 / 5;
			margin: 0;
		}
	}
}
.botao-pdf {
	display: grid;
	grid-template-rows: repeat(1, 1fr);
	grid-template-columns: repeat(12, 1fr);
	.botao {
		grid-column: 10 / 13;
	}
}
