.form-guias{
	display: grid;
	width: 70%;
	grid-template-rows: repeat(4, 1fr);
	grid-template-columns: repeat(12, 1fr);
	align-items: center;
	margin: auto;

	label {
		align: left;
		white-space: nowrap;
	}
	input,select{
		border-radius: 5px;
		border: 1px solid #ececec;
		height: 2em;
		margin: 0.5em;
		text-align: center;
		box-sizing: content-box;
	}
	#fogo {
		grid-column: 2 / 6;
	}
	#saude {
		grid-column: 7 / 12;
	}
	.botao {
		margin-top: 1em;
		margin-bottom: 1em;
		padding: .75em;
		padding-left: 1.5em;
		padding-right: 1.5em;
		color: #FFFFFF;
		background-color: #F3932F;
		grid-column: 6 / 7;
	}
	.botao:hover{
		cursor: pointer;
		background-color: #f77e00;
	}
	@media screen and (max-width: 900px){
		.botao {
			width: 40%;
		}
	}
}
