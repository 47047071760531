.atualizarbox{

	max-width: 50em;
	margin: 1em auto 0 auto;
	font-family: 'Roboto', sans-serif;
	text-align: center;

	.input{
		display: inline;
		border-color: black;
		margin: auto;
		width: 50%;
		font-size: 20px;
	}

	.botao{
		display: inline;
	}

	@media screen and (max-width: 700px){
		.botao {
			display: block;
		}
		.input{
			font-size: 15px;
			width: 80%;
		}
	}
}
