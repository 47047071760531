$navbar: 4rem;
$textAreaHeight: 5em;


#camada_box{
	position: absolute;
	z-index: 2;
	top: 10%;
	right: 0;
	width: 20em;
	height: 25em;
	background-color: rgba(255, 255, 255, 0.5);
	border: solid;
	border-color: rgba(255, 255, 255, 0.5);
	border-top-left-radius: 1em;
	border-bottom-left-radius: 1em;

	input{
		width: 15%;
	}
	.camadaList{
		margin: 10%;
		grid-template-columns: repeat(2,1fr);
		grid-gap: 2em;
		align-items: center;
		grid-template-rows:repeat(6, 1em);
		display: grid;
		text-align: left;
	}


}

#map_box{
	width: 100%;
	height: calc(100vh - #{$navbar});
  font-family: 'Roboto',sans-serif;
	color: black;
	font-weight: bold;

	#map {
		width: 70%;
		height: inherit;
		display: inline-block;
		position: absolute;
	}

		.menuFase2{
			height: inherit;
			width: 30%;
			display: inline-block;
			position: relative;

			#formularioFase2{
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-template-rows:repeat(16, 3em);
				height: inherit;
				overflow-y: scroll;
				align-items: center;

				.upperCase{
					font-size: 17px;
				}

				.centered{
					text-align: center;
				}

				.firstItem{
					margin-top: 2em;
				}

				textarea{
					grid-column: 1 / 5;
					grid-row: 5 / 7;
					height: #{$textAreaHeight};
					resize: none;
					border-radius: 5px;
					margin-right: 1em;
					border: 2px solid #ececec;
				}

				.centeredInput{
					grid-column: 1 / 5;
					margin-right: 1em;
				}

				.centeredButton{
					grid-column: 2 / 4;
				}

				.leftAlignLabel{
					grid-column: 1 / 3;
				}

				.select{
					grid-column: 2 / 4 ;
				}

				.titleLabel{
					grid-column: 1 / 5;
					align-self: end;
				}

				#prodOnu{
					grid-column: 2 / 5;
					margin-left: 1em;
					margin-right: 1em;
				}



				input, select {
					border-radius: 5px;
					border: 2px solid #ececec;
					height: 2em;
					min-width: 0;
					box-sizing: content-box;
				}

				.selectEmpresa{
					grid-column: 1 / 5;
					margin-right: 1em;
				}



			}


		}

}
