.footer {
	position: absolute;
  bottom: 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  background-color: $footerBackground;

  padding-top: 4em;
	padding-bottom: 3em;

  border-top: solid;
  border-top-color: $footerBorder;
  border-top-width: 0.75em;

	width: 100%;

	&.hidden {
		display: none;
	}

	.title {
	  text-align: center;
	  font-size: 30px;
	  padding-right: 3em;
	}

	.logo {
	  text-align: right;
	  padding-right: 4em;

		img {
			width: 100px;
		}
	}

	@media (max-width: 1000px) {
	  .title {
	    text-align: center;
	    padding: 0px;
	  }

		.logo {
			text-align: center;
			padding: 0px;
			height: 100%;
		}
	}

	@media (max-width: 708px) {
	  grid-template-columns: 1fr;
		text-align: center;

		.logo {
			padding: 0;
		}

	  @media (max-width: 400px) {
	    .logo {
	      text-align: center;
	      padding-top: 1em;
	    }
	  }
	}
}
