$display: none;
#grid_ocorrencias {
  display: grid;
  width: 70%;
	grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  margin: auto ;
  margin-bottom: 2em;
  grid-row-gap: 1.5em;
  grid-column-gap: 1em;
  min-height: 0;
  min-width: 0;

  &.br101 {
    #label_sentido, #sentido, #label_quilometro, #quilometro {
      display: none;
    }
  }

  &.cidade {
    #label_cidade, #cidade, #label_bairro, #bairro, #label_logradouro, #logradouro, #numero, #label_numero {
      display: none;
    }
  }
  &.veiculos {
    #label_veiculos_quantidade, #veiculos_quantidade {
      display: none;
    }
  }

  label {
    align: left;
		white-space: nowrap;
    min-width: 0;
  }
  input, select {
    border-radius: 5px;
		border: 2px solid #ececec;
		padding-left: 1em;
		height: 2em;
		margin-right: 1em;
    min-width: 0;
    box-sizing: content-box;
  }

	textarea{
		border-radius: 5px;
		border: 2px solid #ececec;
    padding-top: 1em;
		padding-left: 1em;
		height: 2em;
		margin-right: 1em;
		min-width: 0;
	}

  input:focus {
    outline: none;
  }
  select:focus {
    outline: none;
  }
  input[type=checkbox]{
    height: auto;
    margin-right: 0;
  }

  #label_local{grid-column: 1  / 2;}
  #local{grid-column: 3  / 5;}
  #label_ref{grid-column: 5 / 7;}
  #ref{grid-column: 7 / 13;}
	#label_sentido{grid-column: 1 / 2;}
  #sentido{grid-column: 2 / 5;}
	#label_quilometro{grid-column: 6 / 7;}
  #quilometro{grid-column: 8 / 10;}
  #label_cidade{grid-column: 1 / 2;}
  #cidade{grid-column: 2 / 7;}
	#label_bairro{grid-column: 7 / 8;}
  #bairro{grid-column: 8 / 13;}
	#label_logradouro {grid-column: 1 / 3;}
  #logradouro{grid-column: 3 / 10;}
	#label_numero{grid-column: 10 / 11;}
  #numero{grid-column: 11 / 13;}
	#label_onu{grid-column: 1 / 2;}
	#onu{grid-column: 2 / 5}
  #label_produto{grid-column: 5 / 6;}
  #produto{grid-column: 6 / 13;}
	#label_clima{grid-column: 1 / 2;}
  #clima{grid-column: 2 / 5;}
	#label_vitimas{grid-column: 5 / 6;}
  #vitimas{
    grid-column: 6 / 9;
    padding-left: 0;
  }
	#label_acesso{grid-column: 1 / 2}
  #acesso{grid-column: 2 / 8;}
  #caract{grid-column: 1 / 3;}
  #check_vazamento{grid-column: 4 / 6;}
  #check_incendio{grid-column: 6 / 8;}
  #check_explosao{grid-column: 8 / 10;}
  #check_gotejamento{grid-column: 10 / 12;}
	#check_nenhum{grid-column: 12/13;}
  #entidades{grid-column: 1 / 3;}
  #check_prf{grid-column: 4 / 6;}
  #check_dnit{grid-column: 6 / 8;}
  #check_fepam{grid-column: 8 / 10;}
  #check_defesa_civil{grid-column: 10 / 12;}
	#check_ent_nenhum{grid-column: 12/13;}
  #label_veiculos{grid-column: 1 / 5;}
  #veiculos{grid-column: 6 / 8;}
	#label_veiculos_quantidade { grid-column: 8 / 9;}
  #veiculos_quantidade{grid-column: 9 / 11	;}
  #label_observacoes{grid-column: 1 / 3}
  #observacoes{grid-column: 1 / 13;}
  #relator{grid-column: 1 / 3;}
  #nome{grid-column: 4 / 9;}
  #telefone{grid-column: 10 / 13;}
  .botao{grid-column: 6 / 8;}

  #map_box {
    display: grid;
    grid-template-columns: 250px auto;display: none;
  }

  &.hidden {
    display: none;
  }

  .menu {
    position: relative;
    right: 100vw;
    transition: left 1s;

    z-index: 2;

    padding: 5px;
    background-color: grey;
  }

  .map {
    position: relative;
    right: 100vw;
    transition: right 1.5s;

    z-index: 1;

    width: 100%;
    height: 450px;

    background-color: cyan;
  }

  &.active {
    .menu {
      left: 0;
    }

    .map {
      right: 0;
    }
  }
	@media screen and (max-width: 900px){
		#label_local{grid-column: 1  / 13;}
	  #local{grid-column: 1  / 13;}
	  #label_ref{grid-column: 1 / 13;}
	  #ref{grid-column: 1 / 13;}
		#label_sentido{grid-column: 1 / 13;}
	  #sentido{grid-column: 1 / 13;}
		#label_quilometro{grid-column: 1 / 13;}
	  #quilometro{grid-column: 1 / 13;}
	  #label_cidade{grid-column: 1 / 13;}
	  #cidade{grid-column: 1 / 13;}
		#label_bairro{grid-column: 1 / 13;}
	  #bairro{grid-column: 1 / 13;}
		#label_logradouro {grid-column: 1 / 13;}
	  #logradouro{grid-column: 1 / 13;}
		#label_numero{grid-column: 1 / 13;}
	  #numero{grid-column: 1 / 13;}
	  #label_produto{grid-column: 1 / 13;}
	  #produto{grid-column: 1 / 13;}
		#label_clima{grid-column: 1 / 13;}
	  #clima{grid-column: 1 / 13;}
		#label_vitimas{grid-column: 1 / 13;}
		#vitimas{
	    grid-column: 1 / 13;
	    padding-left: 0;
	  }
		#label_onu{grid-column: 1 / 13;}
		#onu{grid-column: 1 / 13;}
	  #acesso{grid-column: 1 / 13;}
		#label_data{grid-column: 1 / 13;}
	  #data{grid-column: 1 / 13;}
		#label_hora {grid-column: 1 / 13;}
	  #hora{grid-column: 1 / 13;}
	  #caract{grid-column: 1 / 13;}
	  #check_vazamento{grid-column: 1 / 13;}
	  #check_incendio{grid-column: 1 / 13;}
	  #check_explosao{grid-column: 1 / 13;}
	  #check_gotejamento{grid-column: 1 / 13;}
		#check_nenhum{grid-column: 1 / 13;}
	  #entidades{grid-column: 1 / 13;}
	  #check_prf{grid-column: 1 / 13;}
	  #check_dnit{grid-column: 1 / 13;}
	  #check_fepam{grid-column: 1 / 13;}
	  #check_defesa_civil{grid-column: 1 / 13;}
		#check_ent_nenhum{grid-column: 1 / 13;}
	  #label_veiculos{grid-column: 1 / 13;}
	  #veiculos{grid-column: 1 / 13;}
		#label_veiculos_quantidade { grid-column: 1 / 13;}
	  #veiculos_quantidade{grid-column: 1 / 13;}
	  #label_observacoes{grid-column: 1 / 13}
	  #observacoes{grid-column: 1 / 13;}
	  #relator{grid-column: 1 / 13;}
	  #nome{grid-column: 1 / 13;}
	  #telefone{grid-column: 1 / 13;}
		.botao {grid-column: 1 / 13;}
	}
}
#grid_ocorrencias_fase2 {
	display: grid;
  width: 70%;
	grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  margin: auto ;
  margin-bottom: 2em;
  grid-row-gap: 1.5em;
  grid-column-gap: 1em;
  min-height: 0;
  min-width: 0;
  .red {
    color: red;
  }
  .green {
    color: green;
  }
  label {
    align: left;
		white-space: nowrap;
    min-width: 0;
  }
  input, select {
    border-radius: 5px;
		border: 2px solid #ececec;
		padding-left: 1em;
		height: 2em;
		margin-right: 1em;
    min-width: 0;
    box-sizing: content-box;
  }

	textarea{
		border-radius: 5px;
		border: 2px solid #ececec;
    padding-top: 1em;
		padding-left: 1em;
		height: 2em;
		margin-right: 1em;
		min-width: 0;
	}

  input:focus {
    outline: none;
  }
  select:focus {
    outline: none;
  }
  input[type=checkbox]{
    height: auto;
    margin-right: 0;
  }

  #label_ref{grid-column: 1 / 2;}
  #ref{grid-column: 3 / 13;}
	#label_sentido{grid-column: 1 / 2;}
  #sentido{grid-column: 2 / 5;}
	#label_quilometro{grid-column: 6 / 7;}
  #quilometro{grid-column: 8 / 10;}
  #label_cidade{grid-column: 1 / 2;}
  #cidade{grid-column: 2 / 7;}
	#label_bairro{grid-column: 7 / 8;}
  #bairro{grid-column: 8 / 13;}
	#label_logradouro {grid-column: 1 / 3;}
  #logradouro{grid-column: 3 / 10;}
	#label_numero{grid-column: 10 / 11;}
  #numero{grid-column: 11 / 13;}
	#label_onu{grid-column: 1 / 2;}
	#onu{grid-column: 2 / 5}
  #label_produto{grid-column: 5 / 6;}
  #produto{grid-column: 6 / 13;}
	#label_clima{grid-column: 1 / 2;}
  #clima{grid-column: 2 / 5;}
	#label_vitimas{grid-column: 5 / 6;}
  #vitimas{
    grid-column: 6 / 9;
    padding-left: 0;
  }
	#label_acesso{grid-column: 1 / 2}
  #acesso{grid-column: 2 / 8;}
  #caract{grid-column: 1 / 3;}
  #check_vazamento{grid-column: 4 / 6;}
  #check_incendio{grid-column: 6 / 8;}
  #check_explosao{grid-column: 8 / 11;}
  #check_gotejamento{grid-column: 11 / 13;}
  #entidades{grid-column: 1 / 3;}
  #check_prf{grid-column: 4 / 6;}
  #check_dnit{grid-column: 6 / 8;}
  #check_fepam{grid-column: 8 / 11;}
  #check_defesa_civil{grid-column: 11 / 13;}
  #label_veiculos{grid-column: 1 / 5;}
  #veiculos{grid-column: 6 / 8;}
	#label_veiculos_quantidade { grid-column: 8 / 9;}
  #veiculos_quantidade{grid-column: 9 / 11	;}
  #label_observacoes{grid-column: 1 / 3}
  #observacoes{grid-column: 1 / 13;}
  #relator{grid-column: 1 / 3;}
  #nome{grid-column: 4 / 9;}
  #telefone{grid-column: 10 / 13;}
	#label_estadovitimas {grid-column: 1 / 13;}
	#estadovitimas {grid-column: 1 / 13;}
	#label_empresacaminho {grid-column: 1 / 9;}
	#empresacaminho {grid-column: 10 / 13;}
	#empresanome {grid-column: 7 / 13;}
	#empresatexto {grid-column: 1 / 13;}
	#empresacontatonome {grid-column: 2 / 8;}
	#empresacontatotel {grid-column: 9 / 13;}
	#condutortexto {grid-column: 1 / 13;}
	#condutornome {grid-column: 2 / 8;}
	#condutortel {grid-column: 9 / 13;}
	#label_tipoevento {grid-column: 1 / 2;}
	#tipoevento {grid-column: 3 / 7;}
	#label_outroevento {grid-column: 7 / 8;  display: none;}
	#outroevento {grid-column: 8 / 13; display: none;}
	#label_consevento {grid-column: 1 / 3;}
	#consevento {grid-column: 4 / 8;}
	#label_outroconsevento {grid-column: 8 / 9;  display: none;}
	#outroconsevento {grid-column: 9 / 13; display: none;}
	#label_tipoveiculo {grid-column: 1 / 2;}
	#tipoveiculo {grid-column: 3 / 7;}
	#label_outrotipoveiculo {grid-column: 7 / 8;  display: none;}
	#outrotipoveiculo {grid-column: 8 / 13; display: none;}
	#label_tipoembalagem {grid-column: 1 / 2;}
	#tipoembalagem {grid-column: 3 / 7;}
	#label_outrotipoembalagem {grid-column: 7 / 8;  display: none;}
	#outrotipoembalagem {grid-column: 8 / 13; display: none;}
	.botao{grid-column: 6 / 8;}

  #map_box {
    display: grid;
    grid-template-columns: 250px auto;display: none;
  }

  &.hidden {
    display: none;
  }

  .menu {
    position: relative;
    right: 100vw;
    transition: left 1s;

    z-index: 2;

    padding: 5px;
    background-color: grey;
  }

  .map {
    position: relative;
    right: 100vw;
    transition: right 1.5s;

    z-index: 1;

    width: 100%;
    height: 450px;

    background-color: cyan;
  }

  &.active {
    .menu {
      left: 0;
    }

    .map {
      right: 0;
    }
  }
	@media screen and (max-width: 900px){
		#label_local{grid-column: 1  / 13;}
	  #local{grid-column: 1  / 13;}
	  #label_ref{grid-column: 1 / 13;}
	  #ref{grid-column: 1 / 13;}
		#label_sentido{grid-column: 1 / 13;}
	  #sentido{grid-column: 1 / 13;}
		#label_quilometro{grid-column: 1 / 13;}
	  #quilometro{grid-column: 1 / 13;}
	  #label_cidade{grid-column: 1 / 13;}
	  #cidade{grid-column: 1 / 13;}
		#label_bairro{grid-column: 1 / 13;}
	  #bairro{grid-column: 1 / 13;}
		#label_logradouro {grid-column: 1 / 13;}
	  #logradouro{grid-column: 1 / 13;}
		#label_numero{grid-column: 1 / 13;}
	  #numero{grid-column: 1 / 13;}
	  #label_produto{grid-column: 1 / 13;}
	  #produto{grid-column: 1 / 13;}
		#label_clima{grid-column: 1 / 13;}
	  #clima{grid-column: 1 / 13;}
		#label_vitimas{grid-column: 1 / 13;}
		#vitimas{
	    grid-column: 1 / 13;
	    padding-left: 0;
	  }
		#label_onu{grid-column: 1 / 13;}
		#onu{grid-column: 1 / 13;}
	  #acesso{grid-column: 1 / 13;}
		#label_data{grid-column: 1 / 13;}
	  #data{grid-column: 1 / 13;}
		#label_hora {grid-column: 1 / 13;}
	  #hora{grid-column: 1 / 13;}
	  #caract{grid-column: 1 / 13;}
	  #check_vazamento{grid-column: 1 / 13;}
	  #check_incendio{grid-column: 1 / 13;}
	  #check_explosao{grid-column: 1 / 13;}
	  #check_gotejamento{grid-column: 1 / 13;}
	  #entidades{grid-column: 1 / 13;}
	  #check_prf{grid-column: 1 / 13;}
	  #check_dnit{grid-column: 1 / 13;}
	  #check_fepam{grid-column: 1 / 13;}
	  #check_defesa_civil{grid-column: 1 / 13;}
	  #label_veiculos{grid-column: 1 / 13;}
	  #veiculos{grid-column: 1 / 13;}
		#label_veiculos_quantidade { grid-column: 1 / 13;}
	  #veiculos_quantidade{grid-column: 1 / 13;}
	  #label_observacoes{grid-column: 1 / 13}
	  #observacoes{grid-column: 1 / 13;}
	  #relator{grid-column: 1 / 13;}
	  #nome{grid-column: 1 / 13;}
	  #telefone{grid-column: 1 / 13;}
		#label_estadovitimas {grid-column: 1 / 13;}
		#estadovitimas {grid-column: 1 / 13;}
		#label_empresacaminho {grid-column: 1 / 13;}
		#empresacaminho {grid-column: 1 / 13;}
		#empresanome {grid-column: 1 / 13;}
		#empresatexto {grid-column: 1 / 13;}
		#empresacontatonome {grid-column: 1 / 13;}
		#empresacontatotel {grid-column: 1 / 13;}
		#condutortexto {grid-column: 1 / 13;}
		#condutornome {grid-column: 1 / 13;}
		#condutortel {grid-column: 1 / 13;}
		#label_tipoevento {grid-column: 1 / 13;}
		#tipoevento {grid-column: 1 / 13;}
		#label_outroevento {grid-column: 1 / 13;  display: none;}
		#outroevento {grid-column: 1 / 13; display: none;}
		#label_consevento {grid-column: 1 / 13;}
		#consevento {grid-column: 1 / 13;}
		#label_outroconsevento {grid-column: 1 / 13;  display: none;}
		#outroconsevento {grid-column: 1 / 13; display: none;}
		#label_tipoveiculo {grid-column: 1 / 13;}
		#tipoveiculo {grid-column: 1 / 13;}
		#label_outrotipoveiculo {grid-column: 1 / 13;  display: none;}
		#outrotipoveiculo {grid-column: 1 / 13; display: none;}
		#label_tipoembalagem {grid-column: 1 / 13;}
		#tipoembalagem {grid-column: 1 / 13;}
		#label_outrotipoembalagem {grid-column: 1 / 13;  display: none;}
		#outrotipoembalagem {grid-column: 1 / 13; display: none;}

		.botao {grid-column: 1 / 13;}
	}
}
