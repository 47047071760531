@charset "utf-8";

@import "vendor/sw2.scss";

/// variaveis, cores css e etc
@import "config/_colors.scss";
@import "config/_mixins.scss";
@import "config/_normalize.scss";
@import "config/_overrides.scss";

/// componentes, plugins overrides, tabs e etc
@import "components/_container.scss";
@import "components/_footer.scss";
@import "components/_footerbar.scss";
@import "components/_header.scss";
@import "components/_input.scss";
@import "components/_navbar.scss";
@import "components/_switch.scss";
@import "components/_tabela.scss";

/// paginas do sistema
@import "pages/_attdados.scss";
@import "pages/_atualizarcapitao.scss";
@import "pages/_cadastro_empresas.scss";
@import "pages/_cadastro_guias.scss";
@import "pages/_cadastro_produtos.scss";
@import "pages/_cadastro_usuarios.scss";
@import "pages/_index.scss";
@import "pages/_listagem_index.scss";
@import "pages/_login.scss";
@import "pages/_pesquisa_ocorrencias.scss";
@import "pages/_programa.scss";
@import "pages/_registro_ocorrencias_fase2.scss";
@import "pages/_registro_ocorrencias.scss";


/* NÃO COLOCA CÓDIGO AQUI, CARALHO */
