.form-empresas{
	display: grid; // <---------------- ou um
	width: 70%;
	grid-template-rows: repeat(4, 1fr);
	grid-template-columns: repeat(4, 1fr);
	align-items: center;
	margin: auto;
	label{
		color: #e83c37;
		text-align: left;
	}
	input{
		padding-left: 1em;
		border-radius: 5px;
		border: 1px solid #ececec;
		height: 2em;

		margin: 0.5em;
	}
	input:focus{
		outline: none;
	}
	#razaosocial {
		grid-column: 1 / 6;
	}
	#nome {
		grid-column: 1 / 4;
	}
	#cnpj {
		grid-column: 4 / 6;
	}
	#logradouro {
		grid-column: 1 / 3;
	}
	#numero {
		grid-column: 3 / 4;
	}
	#complemento {
		grid-column: 4 / 6;
	}
	#bairro {
		grid-column: 1 / 3;
	}
	#cep {
		grid-column: 3 / 4;
	}
	#cidade {
		grid-column: 4 / 5;
	}
	#estado {
		grid-column: 5 / 6;
	}
	.botao {
		margin-top: 1em;
		margin-bottom: 1em;
		padding: .75em;
		padding-left: 1.5em;
		padding-right: 1.5em;
		color: #FFFFFF;
		background-color: #F3932F;
		grid-column: 3 / 4;
	}
	.botao:hover{
		cursor: pointer;
		background-color: #f77e00;
	}
	@media screen and (max-width: 900px){

		.botao {
			width: 40%;
		}
	}
}
