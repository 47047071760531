header {
	text-align: center;
	margin-top: 2em;

	.usuario-content {
		border-top: 1px solid #F3932F;
		border-bottom: 1px solid #F3932F;
		margin-bottom: 0.75em;
		margin-top: 0.75em;
	}

	.usuario {
		color: #F3932F;
		text-transform: uppercase;
		text-align: center;
		text-transform: uppercase;
	}
}
