.form-produtos{
  display: grid;
	width: 70%;
	grid-template-rows: repeat(7, 1fr);
	grid-template-columns: repeat(3,1fr);
  grid-row-gap: 1em;
	align-items: center;
	margin-top: 3em;
	margin-left: 25%;

	label {
		align: left;
		font-size: 20px;
		white-space: nowrap;
		grid-column: 1;
	}
	input,select{
		border-radius: 5px;
		border: 1px solid #ececec;
    padding-left: 1em;
		height: 2em;
		box-sizing: content-box;
	}

	.button{
		-webkit-border-radius: 10;
	  -moz-border-radius: 10;
	  border-radius: 10px;
	  font-family: 'Roboto';
	  color: #ffffff;
	  font-size: 1em;
	  background-color: #F3932F;
	  padding: .75em;
	  padding-left: 1.5em;
	  padding-right: 1.5em;
	  text-decoration: none;
	  border: none;
		font-size: 1.2em;
	}

	.cadastrar{
		grid-column: 1/2;
		margin-right: 1em;
	}

	.cadastrar:hover{
		background-color: green;
		cursor: pointer;
	}

	.limpar:hover{
		background-color: red;
		cursor:pointer;
	}
	input[type=number]{
		width: auto;
	}

	@media screen and (max-width: 600px){
		grid-template-columns: 1fr;
		text-align: center;
		margin: 10%;
		.cadastrar{margin: 0;}
	}

}
