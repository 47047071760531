.pesquisa {
	.texto-centro {
		text-align: center;
	}
	form {
		margin: 0 100px;
		display: grid;
		grid-template-rows: repeat(3,1fr);
		grid-template-columns: repeat(3,1fr);

		input {
			margin: 0.5em;
		}

		button {
			width: 100%;
			margin: auto;
		}
		.data {
			grid-column: 1 / 2;
		}
		.km {
			grid-column: 2 / 3;
		}
		.cidade {
			grid-column: 3 / 4;
		}
		.onu {
			grid-column: 1 / 2;
		}
		.pesquisar {
			margin-top: 1rem;
			margin-bottom: 1rem;
			grid-column: 1 / 2;
		}
	}
	@media screen and (max-width: 900px){
		.data,.km,.cidade,.codigo,.onu,.pesquisar {
			grid-column: 1 / 3;
		}
	}
}
