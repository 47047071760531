.login-box {
  max-width: 16em;
  background-color: #fff;
  margin: 100px auto 100px auto;
  border-radius: 0.5em;
  padding: 0.05em;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0px 0px 8px 0px rgba(153,153,153,1);

	input {
	  display: block;
	  margin: auto;
	  height: 2.5em;
	  width: 80%;

	  padding-left: 1em;
	  margin-bottom: 1.5em;

	  border-radius: 0.5em;
	  background: #e6e5e5;


	  border-style: solid;
		border-width: 1px;
		border-color: #B8B7B7;

		box-shadow: 0 5px 10px -5px black;
	}

	input:focus {
	  outline: none;
	}
}
