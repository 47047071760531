.entidades {
	> div {
		display: grid;
		align-items: center;


		&:first-child {
			margin-left: 20rem;
			margin-right: 20rem;
			grid-template-columns: repeat(5, minmax(75px, 1fr));
		}

		&:nth-child(2) {
			margin-left: 10rem;
			margin-right: 10rem;
			grid-template-columns: repeat(8, minmax(60px, 1fr));
		}

		> div {
			margin: 1rem;

			img {
				width: 100%;
			}
		}

		@include tablet-landscape-and-below {
			&:first-child {
				margin-left: 10rem;
				margin-right: 10rem;
			}

			&:nth-child(2) {
				margin-left: 60px;
				margin-right: 60px;
			}
		}

		@include tablet-portrait-and-below {
			&:first-child {
				grid-template-columns: repeat(5, 1fr);
			}

			&:nth-child(2) {
				grid-template-columns: repeat(8, 1fr);

				> div {
					margin: .5rem;
				}
			}

			> div {
				margin: .2rem;
			}
		}
	}
}


.entidades {
	> div {
		@media only screen and (max-width : 1215px) {
			&:first-child {
				margin-left: 10rem;
				margin-right: 10rem;
			}

			> div {
				margin: 1rem;
			}
		}

		@include tablet-landscape-and-below {
			&:first-child {
				margin-left: 15rem;
				margin-right: 15rem;
			}

			> div {
				margin: .5rem;
			}
		}

		@media only screen and (max-width : 880px) {
			&:first-child {
				margin-left: 10rem;
				margin-right: 10rem;
			}
		}

		@media only screen and (max-width : 640px) {
			&:first-child {
				margin-left: 5rem;
				margin-right: 5rem;
			}
		}

		@media only screen and (max-width : 480px) {
			&:first-child {
				margin-left: .5rem;
				margin-right: .5rem;
			}

			&:nth-child(2) {
				margin: 0;
				> div {
					margin: .2rem;
				}
			}
		}

		.adjusted{
			width: 70%
		}
	}
}
