.lista {
	table {
		width: 90%;
		margin: auto;
		border-collapse: collapse;
		font-family: 'Roboto';
		margin-bottom: 2em;
		font-size: 1.25em;

		@include desktop-and-below {
			font-size: 1em;
		}

		th {
			background-color: #e83c38;
			color: white;
			padding: 1em;
			text-align: center;
		}

		td {
			border-bottom: 1px solid #e83c38;
			text-align: center;
			padding: 1em;
			color: #333;
		}

	}
	.nome {
		text-align: left;
	}

	.icon{
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		vertical-align: middle;
	}

	.icon:hover{
		cursor: pointer;
	}

	.editIcon{
		width: 3em;
		height: 1.5em;
		margin: 0.25em;
		vertical-align: middle;
	}

	.opacity{
		opacity: 0.2;
	}
}
